import { ChangeDetectionStrategy, Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { BranchDataI } from '@shared';
import { CitiesService } from '@shared/services/pages/cities.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-branch-view',
  templateUrl: './branch-view.component.html',
  styleUrls: ['./branch-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BranchViewComponent implements OnInit {
  
  environment = environment;

  branchInfo$: Observable<BranchDataI>;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly srv: CitiesService,
    private _sanitizer: DomSanitizer
  ) {
    this.branchInfo$ = this.srv.getBranchBySlug(
      this.route.snapshot.params.slug
    );
  }

  ngOnInit() {}
  
  stripTags(html: string): string {
    const str = this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(html));
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = str || '';
    return tempDiv.textContent || '';
  }

  removeHtmlTags(html: string): string {
    return this.stripTags(html)
  }
}
