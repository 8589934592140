<section class="vcim-container container pt-8 pt-lg-16 mb-6 pb-10 pb-xl-16">
  <div *ngIf="branchInfo$ | async as branchInfo; else showSpinner">
    <div class="row mb-12">
      <div *ngIf="branchInfo.photo" class="col-12 col-xs-6 d-flex container-image mx-auto mb-12 mb-md-0">
        <loading-image
          [image]="'/portal/' + branchInfo.photoPath + '/' + branchInfo.photo"
          [alt]="removeHtmlTags(branchInfo.title)"
          [styles]="{
            'object-fit': 'cover',
            'object-position': 'center'
          }"
          class="w-full h-full">
        </loading-image>
      </div>
      <div class="pl-md-16 col-12 col-md-6">
        <div class="f-40 mb-16 fw-600 mb-2">
          <ngx-dynamic-hooks
            [options]="{ sanitize: false }"
            class="vcim-inner-html inner-html-title"
            [content]="branchInfo.title"></ngx-dynamic-hooks>
        </div>

        <div class="vcim-body-1 flex flex-col">
          <span class="mb-3 flex">
            <i class="vcim-icon ic-mail f-20 pr-2 pt-1"> </i>
            <a [href]="'mailto:' + branchInfo.email"> {{ branchInfo.email }} </a>
          </span>
          <span class="mb-3 flex">
            <i class="vcim-icon ic-phone f-20 pr-2 pt-1"> </i>
            <a [href]="'tel:' + branchInfo.phone"> {{ branchInfo.phone }} </a>
          </span>
          <span class="mb-3 flex">
            <i class="vcim-icon ic-location f-20 pr-2 pt-1"> </i>
            <span class="fw-700">
              {{ branchInfo.address }}
            </span>
          </span>
          <div class="mb-3 flex" *ngIf="branchInfo.workingHours?.length">
            <i class="vcim-icon ic-time f-20 pr-2 pt-1"> </i>
            <div class="vcim-body-1 d-flex flex-col">
              <span class="fw-700"> {{ 'website.branches.working-hours' | translate }} </span>
              <span *ngFor="let item of branchInfo.workingHours; let i = index" class=""> {{ item.title }} {{ item.description }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ngx-dynamic-hooks
          [options]="{ sanitize: false }"
          class="vcim-body-1 vcim-inner-html"
          [content]="branchInfo.description"></ngx-dynamic-hooks>
      </div>
    </div>
  </div>
</section>

<ng-template #showSpinner>
  <div style="height: 100px" class="my-10 d-flex justify-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
